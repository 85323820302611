// Main Inicializado!
console.log('%cquiz-ods: Main.js Inicializado!', 'padding: 2px 10px; background-color: green; color:#FFF;');


(function($, scope){

	this.MainClass = {

		urlpath: "/pacto-global/quiz",

		tipsContent: {
			agua: function(){
				var content = '\
				<h3>água</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Use a água descartada da máquina de lavar para fazer a limpeza de pisos e lavar o quintal, assim você economiza água e contribui para redução do consumo desse recurso natural.</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://www.youtube.com/watch?v=SKqyB3pwbjE" class="modal-link">Pegada hídrica</a>\
				<a href="#" class="modal-link">Documentário Brave Blue World</a>\
				<a target="_blank" href="https://especiais.g1.globo.com/economia/crise-da-agua/calculadora-do-consumo/" class="modal-link">Calculadora de água</a>\
				<a target="_blank" href="https://www.instagram.com/arvoreagua/" class="modal-link">@arvoreagua</a>\
				';



				return content;
			}(),

			lixo: function(){
				var content = '\
				<h3>resíduos</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Comece separando os lixos em sua casa entre recicláveis e não recicláveis, com o andar do tempo você irá se acostumar e passará a separar o próprio resíduo reciclável entre seus tipos de materiais</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
				<a target="_blank" href="https://www.instagram.com/reciclasampa/" class="modal-link">@reciclasampa</a>\
                <a target="_blank" href="https://www.youtube.com/watch?v=o0EYneEPUmQ" class="modal-link">Como fazer uma composteira</a>\
				<a href="#" class="modal-link">Filme Wall-E</a>\
				';

				return content;
			}(),

			consumo: function(){
				var content = '\
				<h3>consumo</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Avalie seu guarda-roupa, nem tudo que compramos nós usamos e nem tudo que temos precisamos em grandes quantidades. Doe e torne seu consumo mais consciente.</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://slaveryfootprint.org/#whats_on_your_plate" class="modal-link">Slavery Footprint</a>\
				<a target="_blank" href="https://www.youtube.com/watch?v=7qFiGMSnNjw" class="modal-link">A história das coisas</a>\
				<a target="_blank" href="https://www.youtube.com/watch?v=EDBEDtGH-8k" class="modal-link">A cultura do desperdício</a>\
				<a target="_blank" href="https://www.netflix.com/br/title/80114460" class="modal-link">Documentário Minimalism</a>\
				';

				return content;
			}(),

			mobilidade: function(){
				var content = '\
				<h3>mobilidade</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Se é possível chegar em pouco tempo, por que não ir a pé ou de bike? Essa prática, além de ajudar o meio ambiente, contribui para sua saúde e qualidade de vida.</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://www.youtube.com/watch?v=o53FSMNfDio" class="modal-link">Perregue – O desafio de mobilidade em São Paulo</a>\
				<a target="_blank" href="https://www.netflix.com/pt/title/80075312" class="modal-link">Documentário Bike versus Cars</a>\
				';

				return content;
			}(),

			alimento: function(){
				var content = '\
				<h3>alimentação</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Uma alimentação mais equilibrada é reflexo na saúde e no meio ambiente, que tal diminuir o consumo de carne? Um dia da semana já é um grande começo e gera inúmeros resultados!</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://www.youtube.com/watch?v=EJvyHLO9Eus&t=294s" class="modal-link">Agroecologia</a>\
				<a target="_blank" href="https://sustainable.vhx.tv/" class="modal-link">Documentário Sustainable</a>\
				<a target="_blank" href="https://www.instagram.com/segundasemcarne/" class="modal-link">@segundasemcarne</a>\
				';

				return content;
			}(),

			residuos: function(){
				var content = '\
				<h3>florestas e matas</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Pesquise e se informe sobre o desmatamento e queimadas nas áreas de florestas e matas nativas. Procure instituições que lutam para sua preservação.</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://globoplay.globo.com/v/8708608/?gclsrc=aw.ds&&gclid=Cj0KCQjwjN-SBhCkARIsACsrBz4FTfakJTUkDjbWn-pPlIVZliyZv9MuR7TSkhPGW8mM1XOGnyR783saAnr7EALw_wcB" class="modal-link">Documentário Amazônia Sociedade Anômina</a>\
				<a target="_blank" href="https://globoplay.globo.com/amazonia-em-chamas/t/gwFpS11nbt/" class="modal-link">Documentário Amazônia em Chamas</a>\
				<a target="_blank" href="https://www.instagram.com/SOSMataAtlantica/" class="modal-link">@SOSMataAtlantica</a>\
				<a target="_blank" href="https://www.instagram.com/SOSPantanal/" class="modal-link">@SOSPantanal</a>\
				<a target="_blank" href="https://www.instagram.com/342amazonia/" class="modal-link">@342amazonia</a>\
				<a target="_blank" href="https://www.instagram.com/povosindigenas/" class="modal-link">@povosindigenas</a>\
				';

				return content;
			}(),

			desigualdade: function(){
				var content = '\
				<h3>desigualdade</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Olhe a sua volta, quanta diversidade tem nela? Procure incluir os grupos minoritários no seu dia a dia.</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://www.youtube.com/watch?v=Hh6ra-18mY8" class="modal-link">Ilha das Flores</a>\
				<a target="_blank" href="https://www.youtube.com/watch?v=JVN0ij8kADQ" class="modal-link">Planejamento familiar</a>\
				<a target="_blank" href="https://www.primevideo.com/detail/0LLBMMKIGDEU5ZF10BMNPZ2935/ref=atv_dp?language=pt_br" class="modal-link">Filme Cafarnaum</a>\
				<a target="_blank" href="https://www.instagram.com/_politize/" class="modal-link">@_politize</a>\
				<a target="_blank" href="https://www.instagram.com/onumulheresbr/" class="modal-link">@onumulheresbr</a>\
				';

				return content;
			}(),

			escolha: function(){
				var content = '\
				<h3>escolhas e impactos</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Você sabe qual o plano de governo do prefeito que atua em sua cidade? Comece por ai! Participe ativamente das políticas públicas, VOCÊ TEM PODER!</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://www.tre-se.jus.br/eleicoes/eleicoes-2022/eleicoes-2022" class="modal-link">TER - eleições</a>\
				<a target="_blank" href="https://www.youtube.com/watch?v=NwBE6Jhiphw" class="modal-link">Documentário Planeta dos humanos</a>\
				<a target="_blank" href="https://www.footprintcalculator.org/home/en" class="modal-link">Calculadora de impacto</a>\
				';

				return content;
			}(),

			engajamento: function(){
				var content = '\
				<h3>engajamento</h3>\
				<p class="modal-text pink">Para começar sua jornada mais sustentável: </p>\
                <p class="modal-text white">Seja um multiplicador! Compartilhe esse quiz com seu núcleo e os incentive a ter escolhas mais sustentáveis, contribuindo com a agenda 2030 da ONU!</p>\
                <p class="modal-text green">saiba mais clicando abaixo</p>\
                <a target="_blank" href="https://www.instagram.com/engajamundo/" class="modal-link">Engaja mundo</a>\
                <a target="_blank" href="https://www.instagram.com/imagine2030/" class="modal-link">@Imagine2030</a>\
				<a target="_blank" href="https://www.youtube.com/watch?v=E9jmHBzh-yc" class="modal-link">Seremos História?</a>\
				<a target="_blank" href="https://www.netflix.com/br/title/80168188" class="modal-link">Documentário Em Busca dos Corais</a>\
				';

				return content;
			}(),
		},

		// Document Ready Scope votorantim
		ready: function(){
			$(document).trigger('votorantim.ready');
		},
		// Document OnLoad Scope votorantim
		load: function(){
			$(document).trigger('votorantim.load');
		},
		// Init Scope votorantim
		init: function(){
			$(document).trigger('votorantim.init');

			// Inicializando as funções comuns a todas as páginas
			this.header();
			this.common();
			this.footer();
		},

		// Function Header
		// Funções JS presentes no Header
		header: function(){

		},

		// Function Footer
		// Funções JS presentes no Footer
		footer: function(){

		},

		// Function Common
		// Inserir aqui o js comum a todas as páginas!
		common: function(){

			// Função para inicializar Funções em páginas específicas!
			var _page = jQuery('body').attr('class'), _page = _page.split(' ');
			if(_page.length){
				_page.map(function(e,i){
				if(typeof scope.pages[e] == 'function'){
					scope.pages[e].call(scope);
				}
				});
			}
		},

		// Funções de páginas específicas
		// Inserir aqui função para cada página que tenha recursos específicos!
		pages: {

			'votorantin': function(){},
			'home-page': function(){

				$(".btn-start").on('click', function(){
					dataLayer.push({
						event: 'startPlay',
						cta: 'first'
					});
				});

				$(".btn-info").on('click', function(){
					$("#modal").css('display', 'block');
					$("#modal").animate({opacity: "1"}, 1000)
				});

				$(".modal-close img").on('click', function(){
					$("#modal").animate({opacity: "0"}, 1000).css('display', 'none');
				});

			},
			'intro': function(){

				$(".buttom-next-hand .circle-hand").on('click', function(){
					dataLayer.push({
						event: 'startPlay',
						cta: 'last'
					});
					window.location.href = scope.urlpath + '/pergunta-1';
				});

			},
			'quest': function(){

				console.log('%cvotorantim: Questions!', 'padding: 2px 10px; background-color: green; color:#FFF;');

				var questions = jQuery('body').attr('class'), questions = questions.split(' ');
				if(questions.length){
					var resp = questions[1];
					if(localStorage.getItem(resp) != null){
						response = localStorage.getItem(resp);
						$('.alternatives input[name='+resp+']').each(function(index, el){
							if($(el).val() == response){
								$(el).prop('checked', true);
							}
						});
					}
				}

				$(".btn-prev-ht").on('click', function(event){

					event.preventDefault();

					quest = $(this).parent().parent().parent().attr('class');
					quest = quest.split(" ");

					switch(quest[1]){
						case "question-2": {
							window.location.href = scope.urlpath + '/pergunta-1';
							break
						}

						case "question-3": {
							window.location.href = scope.urlpath + '/pergunta-2';
							break
						}

						case "question-4": {
							window.location.href = scope.urlpath + '/pergunta-3';
							break
						}

						case "question-5": {
							window.location.href = scope.urlpath + '/pergunta-4';
							break
						}

						case "question-6": {
							window.location.href = scope.urlpath + '/pergunta-5';
							break
						}

						case "question-7": {
							window.location.href = scope.urlpath + '/pergunta-6';
							break
						}

						case "question-8": {
							window.location.href = scope.urlpath + '/pergunta-7';
							break
						}

						case "question-9": {
							window.location.href = scope.urlpath + '/pergunta-8';
							break
						}

						case "question-10": {
							window.location.href = scope.urlpath + '/pergunta-9';
							break
						}
					}

				});

				$(".btn-next-ht").on('click', function(event){
					event.preventDefault();
					quest = $(this).parent().parent().parent().attr('class');
					quest = quest.split(" ");

					console.log(quest[1]);

					switch(quest[1]){
						case "question-1": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question1]:checked');

							if(el.length){
								localStorage.setItem('question1', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question1]:checked').val()));
								scope.eventQuestions('question1', $(this).parent().parent().parent().find('.alternatives input[name=question1]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-2';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}

							break;
						}

						case "question-2": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question2]:checked');

							if(el.length){
								localStorage.setItem('question2', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question2]:checked').val()));
								scope.eventQuestions('question2', $(this).parent().parent().parent().find('.alternatives input[name=question2]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-3';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}
							break;
						}

						case "question-3": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question3]:checked');

							if(el.length){
								localStorage.setItem('question3', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question3]:checked').val()));
								scope.eventQuestions('question3', $(this).parent().parent().parent().find('.alternatives input[name=question3]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-4';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}
							break;
						}

						case "question-4": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question4]:checked');

							if(el.length){
								localStorage.setItem('question4', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question4]:checked').val()));
								scope.eventQuestions('question4', $(this).parent().parent().parent().find('.alternatives input[name=question4]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-5';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}

							break;
						}

						case "question-5": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question5]:checked');

							if(el.length){
								localStorage.setItem('question5', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question5]:checked').val()));
								scope.eventQuestions('question5', $(this).parent().parent().parent().find('.alternatives input[name=question5]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-6';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}

							break;
						}

						case "question-6": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question6]:checked');

							if(el.length){
								localStorage.setItem('question6', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question6]:checked').val()));
								scope.eventQuestions('question6', $(this).parent().parent().parent().find('.alternatives input[name=question6]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-7';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}

							break;
						}

						case "question-7": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question7]:checked');

							if(el.length){
								localStorage.setItem('question7', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question7]:checked').val()));
								scope.eventQuestions('question7', $(this).parent().parent().parent().find('.alternatives input[name=question7]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-8';
							} else {

								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}
							break;
						}

						case "question-8": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question8]:checked');

							if(el.length){
								localStorage.setItem('question8', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question8]:checked').val()));
								scope.eventQuestions('question8', $(this).parent().parent().parent().find('.alternatives input[name=question8]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-9';
							} else {
								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}
							break;
						}

						case "question-9": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question9]:checked');

							if(el.length){
								localStorage.setItem('question9', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question9]:checked').val()));
								scope.eventQuestions('question9', $(this).parent().parent().parent().find('.alternatives input[name=question9]:checked').val());
								window.location.href = scope.urlpath + '/pergunta-10';
							} else {
								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}
							break;
						}

						case "question-10": {

							el = $(this).parent().parent().parent().find('.alternatives input[name=question10]:checked');

							if(el.length){
								localStorage.setItem('question10', parseInt($(this).parent().parent().parent().find('.alternatives input[name=question10]:checked').val()));
								scope.eventQuestions('question10', $(this).parent().parent().parent().find('.alternatives input[name=question10]:checked').val());
								window.location.href = scope.urlpath + '/visualizar-o-resultado';
							} else {
								$(this).parent().parent().parent().find('.alternatives .control_indicator').css('border-color', 'red');
							}
							break;

						}
					}

					$("html, body").animate({ scrollTop: 0 }, "slow");
				});

				$(".control_indicator").on('click', function(){
					$(".control_indicator").css("border-color", "white");
					console.log($(this).parent().parent().parent().parent().parent().parent().parent().find('.btn-mobile-desktop span'));
					$(this).parent().parent().parent().parent().parent().parent().parent().find('.btn-mobile-desktop span').css("background-color", "#0FDE8E")
				});
			},
			'waiting': function(){


				soma = 0;
				for(i = 0; i < 10; i++){
					question = 'question' + (i+1);

					if(localStorage.getItem(question) != null) {
						soma = soma + parseInt(localStorage.getItem(question));
						localStorage.removeItem(question);
					} else {
						window.location.href = scope.urlpath + '/pergunta-1';
					}
				}

				jQuery('.btn-result').on('click', function(){

                    switch(true){
                        case soma < -9: {
							dataLayer.push({
								event: 'seeResult',
								value: 'A caminho da extinção em massa'
							});
                            window.location.href = scope.urlpath + "/a-caminho-da-extincao-em-massa";
                            break;
						}

                        case soma < 20: {
							dataLayer.push({
								event: 'seeResult',
								value: 'O mundo como o conhecemos um pouco pior'
							});
                            window.location.href = scope.urlpath + "/o-mundo-como-o-conhecemos-um-pouco-pior";
                            break;
						}

                        case soma < 55: {
							dataLayer.push({
								event: 'seeResult',
								value: 'Alguns motivos para celebrar'
							});
                            window.location.href = scope.urlpath + "/alguns-motivos-para-celebrar";
                            break;
						}

                        default: {
							dataLayer.push({
								event: 'seeResult',
								value: 'Um futuro radicalmente diferente'
							});
                            window.location.href = scope.urlpath + "/um-futuro-radicalmente-diferente";
                            break;
						}
                    }

                });




				setTimeout(function(){
					$(".letter1").css("opacity", '1');
				}, 1500);
				setTimeout(function(){
					$(".letter2").css("opacity", '1');
				}, 1500);
				setTimeout(function(){
					$(".letter3").css("opacity", '1');
				}, 2500);
				setTimeout(function(){
					$(".letter4").css("opacity", '1');
				}, 2500);
				setTimeout(function(){
					$(".letter5").css("opacity", '1');
				}, 3500);
				setTimeout(function(){
					$(".letter6").css("opacity", '1');
				}, 4500);
				setTimeout(function(){
					$(".letter7").css("opacity", '1');
				}, 5500);
				setTimeout(function(){
					$(".letter8").css("opacity", '1');
				}, 6500);

				setTimeout(function(){
					$("body").removeClass('waiting');
					$("#waiting").css('display', 'none');
					$("body").addClass('check');
					$("#check").css("display", 'block');
					$("#check").addClass("sec-active");
					$("#check .sec-opacity").css("opacity", '1');
				}, 7200);

			},
			'result': function(){

				console.log('%cvotorantim: Results!', 'padding: 2px 10px; background-color: green; color:#FFF;');

                //Exibir e tratar screen-2
                jQuery('.content-result .circle-hand-see').on("click", function(){

                    $("html, body").animate({ scrollTop: 0}, "slow");

                    jQuery('.screen-1').css({'display':'none'});
                    jQuery('.screen-2').css({'display':'block'});
                    jQuery('.screen-2').addClass('kf-opacity-answer');

                    //Extincao
                    if(jQuery("body.result-extincao").length){
                        jQuery("body").addClass('results-yellow-light');
                        jQuery("body.result-extincao").removeClass('results-red-linear-0');
                        jQuery("#footer").addClass('results-red-linear-2');
                    }

                    //Um pouco pior
                    if(jQuery("body.result-pior").length){
                        jQuery("body").addClass('results-red');
                        jQuery("body.result-pior").removeClass('results-orange-linear-0');
                        jQuery("#footer").addClass('results-orange-linear-2');
                    }

                    //Celebrar
                    if(jQuery("body.result-celebrar").length){
                        jQuery("body").addClass('results-yellow-light');
                        jQuery("body").removeClass('results-blue-linear');
                        jQuery("body").removeClass('results-blue-linear-2');
                        jQuery("#footer").addClass('results-blue-linear-2');
                    }

                    //Futuro radicalmente diferente
                    if(jQuery("body.result-futuro").length){
                        jQuery("#footer").addClass("results-purple-linear-2");
                    }

                    //Evento DataLayer
                    dataLayer.push(
                        {
                            'event': 'readMoreResult',
                            'value': jQuery("meta[name=title]").attr('content')
                        }
                    );

                    jQuery(".results-cta").on("click", function(){
                        dataLayer.push(
                            {
                                event: 'preShareScreen'
                            }
                        );
                    })

                });

                //Verificar result Alguns motivos para celebrar
                if(jQuery(".result-celebrar").length){
                    jQuery(".result-celebrar ").addClass("results-blue-linear-2");
                    jQuery(".result-celebrar #footer").addClass("results-blue-linear-2");
                }

                //Verificar result Alguns motivos para celebrar
                if(jQuery(".result-futuro").length){
                    jQuery("#footer").addClass("results-green-linear-2");
                }

            },
			'share': function(){

                console.log('%cvotorantim: Share!', 'padding: 2px 10px; background-color: green; color:#FFF;');

                //Abrir botões de compartilhamento
                jQuery(".share-cta").on("click", function(){

                    jQuery(".share-1").addClass("kf-opacity-share");
                    jQuery(".share-1").css({"display":"flex"});

                    jQuery(".share-1").mouseleave(function(){
                        jQuery(".share-1").css({"display":"none"});
                        jQuery(".share-1").removeClass("kf-opacity-share");
                    });
                })

                //DataLayer Event - redes sociais
                jQuery(".container-socials-share li a").on('click', function(){

                    dataLayer.push(
                        {
                            event: 'share',
                            network: jQuery(this).data('sharer')
                        }
                    );
                })

                //DataLayer Event - Quero Dicas
                jQuery(".tips-cta").on('click', function(){

                    dataLayer.push(
                        {
                            event: 'hints'
                        }
                    );
                })

                //DataLayer Event - Enviar Quiz whatsapp
                jQuery(".circle-hand-leia").on('click', function(){
                    dataLayer.push(
                        {
                            event: 'shareWhatsApp'
                        }
                    );
                })
            },
			'tips': function(){

				$("#tips .item-block").on('click', function(){

					if($(this).hasClass('m-agua')){
						$("#modal .modal-content").html(scope.tipsContent.agua);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-lixo')){
						$("#modal .modal-content").html(scope.tipsContent.lixo);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-consumo')){
						$("#modal .modal-content").html(scope.tipsContent.consumo);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-desigualdade')){
						$("#modal .modal-content").html(scope.tipsContent.desigualdade);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-residuos')){
						$("#modal .modal-content").html(scope.tipsContent.residuos);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-alimento')){
						$("#modal .modal-content").html(scope.tipsContent.alimento);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-mobilidade')){
						$("#modal .modal-content").html(scope.tipsContent.mobilidade);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-escolha')){
						$("#modal .modal-content").html(scope.tipsContent.escolha);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					} else if($(this).hasClass('m-engajamento')){
						$("#modal .modal-content").html(scope.tipsContent.engajamento);
						$("#modal").css('display', 'block');
						$("#modal").animate({opacity: "1"}, 1000)
					}

				});

				$(".modal-close img").on('click', function(){
					$("#modal").animate({opacity: "0"}, 1000).css('display', 'none');
				});

			},

		},

		// Atribui o token de segurança a requisição ajax para
		// realizar a comunicação com segurança
		security: function()
		{
			$.ajaxSetup({
				headers: {
					'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
				}
			});
		},

		eventQuestions: function(question, value){

			var alt = 1;
			$('.alternatives input[name='+question+']').each(function(index, el){
				if($(el).val() == value){
					cont = $(el).parent().find('span').text();
					return false;
				}
				alt++;
			});

			dataLayer.push({
				event: 'question',
				number: alt,
				answer: cont
			});
		},
	};


	// Criação e inicialização do escopo da função principal
	scope = this.MainClass;
	scope.init();
	window.votorantim = scope;

	// Scope.Ready and Scope.Load
	$(document).ready(function(){ scope.ready(); });
	$(window).on('load',function(){ scope.load(); });

})(jQuery, {});
